.form-page-container {
  display: flex;
  padding: 0 0 6.25rem 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  align-self: stretch;
}

.form-section-container {
  display: flex;
  padding: 2rem 0;
  align-items: flex-start;
  align-content: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
  border-top: 1px solid var(--border-color, #D0D5DD);
}

.form-section-container-base {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
}

.required-dot {
  color: var(--alert-messaging-error-text-color, #F04438);
  font-size: 0.75rem;
  font-style: normal;
  white-space: pre;
  font-weight: 400;
  line-height: 175%;
}

.form-step-section-container {
  display: flex;
  padding-top: 2rem;
  padding-bottom: 3rem;
  align-items: flex-start;
  align-content: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
  border-top: 1px solid var(--border-color, #D0D5DD);
  border-bottom: 1px solid var(--border-color, #D0D5DD);
}

.tab-form-section-container {
  display: flex;
  padding: 2rem 0;
  align-items: flex-start;
  align-content: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
  border-top: 1px solid var(--border-color, #D0D5DD);
}

.configuration-form-container {
  @media only screen and (max-width: 1439px) {
    min-width: 28rem;
  }
  @media only screen and (min-width: 1440px) {
    max-width: 46rem;
  }

  width: 100%;

  .configuration-form-container-spacing {
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
  }
}

.configuration-multi-input-container {
  display: flex;
  padding: 0;
  align-items: flex-start;
  gap: var(--spacing-8-x, 2rem);
  align-self: stretch;
}

.configuration-input-flex-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 0 0;
}

.flex-half {
  @media only screen and (max-width: 900px) {
    width: 47% !important;
  }
  @media only screen and (min-width: 901px) {
    width: 48% !important;
  }
}

.configuration-toggle-field {
  display: flex;
  height: 2.625rem;
  align-items: center;
  align-self: stretch;
  min-width: 7.3rem;
  flex-grow: 1;

  div {
    width: 100%;
  }
}



.tabview-dropdown-sizing {
  min-width: 15rem;
  max-width: 15rem;
  width: 100%;
}

.dropdown-sizing {
  @media only screen and (max-width: 1339px) {
    min-width: 10rem;
  }
  @media only screen and (min-width: 1340px) {
    min-width: 22rem;
  }

  width: 100%;

  div {
    width: 100%;
  }
}

.input-sizing {
  @media only screen and (max-width: 1339px) {
    min-width: 10rem;
  }
  @media only screen and (min-width: 1340px) {
    min-width: 22rem;
  }

  width: 100%;
}

.phone-dropdown-sizing {
  flex-grow: 0;
  width: auto;

  .div {
    min-width: 7.5rem;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.form-input-dropdown-sizing {
  display: flex;
  width: auto;

  div {
    display: flex;
    width: auto;
    flex-grow: 0;
  }
}

.tablet-wrap {
  @media only screen and (max-width: 1339px) {
    flex-wrap: wrap;
  }
  @media only screen and (min-width: 1340px) {
    flex-wrap: nowrap;
  }
}



.ext-size {
  @media only screen and (max-width: 1339px) {
    max-width: 6rem !important;
  }
  @media only screen and (min-width: 1340px) {
    max-width: 9.375rem !important;
  }
}

.enabled-label-form {
  display: flex;
  padding: 4px 8px;
  height: 100%;
  width: 5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
}
