/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700');

/* Assume light mode by default */
@import "themes/light/theme.css";
/* Supersede dark mode when applicable */
@import "themes/dark/theme.css" screen and (prefers-color-scheme: dark);
/* Table styling standards */
@import "/src/app/global/styling/atom-table";
/* Flex styling */
@import "/src/app/global/styling/atom-flex.scss";
/* Form Group styling */
@import "/src/app/global/styling/form-groups.scss";
/* Flex styling */
@import "/src/app/global/styling/svg-styling.scss";
/* Override styling for PrimeNG components */
@import "/src/app/global/styling/prime-ng-override.scss";

body {
  font-family: Inter, serif;
}

.header-dropdown {
  .p-dropdown-item-group:first-of-type {
    display: none;
  }

  .p-dropdown-item-group {
    background: transparent !important;
  }
}

.primary-text-color {
  color: var(--nav-item-inverted);
}

.secondary-text-color {
  color: var(--text-color-secondary);
}

.material-icons {
  font-weight: 300 !important;
}

.material-symbols-200 {
  font-weight: 200 !important;
}

.material-symbols-sharp {
  font-weight: 300 !important;
}

.material-symbols-sharp.icon-color {
  color: var(--site-feedback-content-text);
}

.material-symbols-sharp.icon-color-active {
  color: var(--nav-collapsed-badge) !important;
}

.material-symbols-sharp.icon-color-completed {
  color: var(--nav-item) !important;
}

.custom-chip {
  border: 1px solid var(--nav-collapsed-badge);
  padding: 4px 8px;
  height: 29px;
  background: transparent;
  color: var(--nav-collapsed-badge);
}

.empty {
  .p-datatable-wrapper {
    overflow-x: hidden !important;
    overflow-y: auto;
  }
}

.analytics-timeframe-multiselect-panel {
  width: 276px;
}

//removes X icon from the search header of the analytics filter timeframe multiselect
.analytics-timeframe-multiselect-panel .p-multiselect-close {
  display: none !important;
}

.widget-table-error-padding {
  padding-top: 64px;
  padding-bottom: 64px;
}

.widget-row-top {
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  gap: 2rem var(--spacing-8-x, 2rem);
  flex-wrap: wrap;
}

.widget-row {
  margin: 1rem 0 1rem 0;
  display: flex;
  width: 100%;
  gap: 2rem var(--spacing-8-x, 2rem);
  flex-wrap: wrap;
}

.widget-row-bottom {
  margin: 1rem 0 2rem 0;
  display: flex;
  width: 100%;
  gap: 2rem var(--spacing-8-x, 2rem);
  flex-wrap: wrap;
}

.p-progress-spinner-circle {
  stroke: var(--nav-collapsed-badge) !important;
}

.p-left-nav-select {
  .p-selectbutton p-buttonset p-c {
    .p-ripple p-element p-button {
      min-width: 7rem;
    }
  }
}

.p-toast-icon-close {
  border-radius: 4px !important;
  box-shadow: 0 0 0 0 !important;
}

.p-toast-message-content {
  align-items: center !important;
}

.outline-button {
  button {
    background: none !important;
    color: var(--nav-collapsed-badge) !important;
    gap: 8px
  }
}

.button-height {
  height: 42px;
}

.flex-wrap-center {
  @media only screen and (max-width: 1139px) {
    width: 100%;
    justify-content: center !important;
    margin-bottom: 1rem;
  }
}

.flex-wrap-mt-2 {
  @media only screen and (max-width: 1139px) {
    margin-top: 0;
  }
  @media only screen and (min-width: 1140px) {
    margin-top: 8px
  }
}

.content-delete-button {
  button {
    background: none !important;
    border: 2px solid var(--content-bg-color) !important;
    color: var(--analytics-error-state) !important;
    gap: 8px
  }

  button:hover {
    border: 2px solid !important;
  }
}

.frozen-row:nth-of-type(odd) {
  background: var(--table-nth-odd) !important;
}

.frozen-cell-z-999 {
  z-index: 999;
}

.input-number-config-input-field {
  display: flex;
  width: 100%;

  span {
    display: flex;
    width: 100%;

    input {
      display: flex;
      width: 100%;
      height: 2.625rem;
      padding: 0.5rem;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }
  }
}

.configuration-input-field {
  display: flex;
  height: 2.625rem;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.configuration-input-field-password {
  display: flex;
  height: 2.625rem;
  justify-content: space-between;
  padding: 0;
  align-items: center;
  align-self: stretch;

  div {
    display: flex;
    width: 100%;
    height: 2.625rem;
    padding: 0;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    input {
      display: flex;
      width: 100%;
      height: 2.625rem;
      padding: .5rem;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }
  }
}

.configuration-input-label-field {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  align-self: stretch;
}

.configuration-label-field {
  display: flex;
  height: 2.625rem;
  padding: 0.5rem;
  font-weight: 200;
  font-size: 12px;
  padding-left: 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}



.tooltip-custom-size {
  .p-tooltip-text {
    width: auto !important;
  }
}

.no-results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-4-x, 1rem);
  align-self: stretch;
}

.left-nav-dropdown {
  div {
    ul {
      p-dropdownitem {
        li {
          padding: 0 !important;

          div {
            padding: .7rem 1rem;

            div {
              display: flex;
              height: 1.3125rem;
              align-items: center;
              flex: 1 0 0;
            }
          }
        }
      }
    }
  }
}

.text-secondary-14px {
  color: var(--text-color-secondary, #98A2B3);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

///////////////////////////////////////////////
//         Widget header button styling     //
///////////////////////////////////////////////

.right-header-button-container {
  display: flex;
  width: 48px;
  height: 48px;
  padding: var(--spacing-2-x, 0.5rem);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-xs, 0.25rem);
}

.right-header-button-container:hover {
  border-radius: 4px;
  background: var(--nav-bg-hover);
}

.search-svg-48-px {
  left: 245px;
  top: 0.3375rem;
  flex-shrink: 0;
}

.search-svg-42-px {
  left: 248px;
  top: 0.3375rem;
  flex-shrink: 0;
}

.svg-fill {
  fill: var(--nav-item-inverted) !important;
  color: var(--nav-item-inverted) !important;
}

.dialog-border {
  border: 1px solid var(--borders-dividers-border-color, #344054);
}

.left-nav-button {
  display: flex;
  height: 3rem;
  border: none;
  width: 14rem;
  background-color: transparent !important;
  color: var(--nav-item-inverted) !important;

  .p-ripple {
    display: flex;
    border-radius: 4px;
    width: 14rem;
    border: none;
    padding: 0;
    gap: 0.5rem;
    font-weight: 400 !important;
    background-color: transparent !important;
    color: var(--nav-item-inverted) !important;
    box-shadow: none;

    &:hover {
      background-color: var(--nav-bg-hover) !important;
    }
  }


}

.active {
  .p-ripple {
    display: flex;
    border-radius: 4px;
    width: 14rem;
    border: none;
    padding: 0;
    gap: 0.5rem;
    font-weight: 400 !important;
    color: var(--nav-item) !important;
    background-color: var(--primary-500) !important;
    box-shadow: none;

    &:hover {
      border: none;
      background-color: var(--primary-500) !important;
    }
  }
}

.select-button-sizing {
  div {
    div {
      width: 94px !important;
      height: 42px !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      line-height: 21px !important;
    }
  }
}

.form-elect-button-sizing {
  div {
    div {
      width: 14.28% !important;
      height: 42px !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      line-height: 21px !important;
    }
  }
}

.esg-widget-spacing {
  padding: 24px 24px 32px 24px;
  gap:  32px;
}
