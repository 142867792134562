
.p-calendar-styling {
  border: 1px solid var(--table-inactive);
  border-radius: 4px;

  &:hover {
    border: 1px solid var(--calendar-hover-color) !important;
  }

  &:focus-within {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem var(--calendar-focus-color);
    border: 1px solid var(--calendar-hover-color);
  }

  span {
    input {
      border: 0;

      &:focus {
        outline: 0 none !important;
        outline-offset: 0 !important;
        box-shadow: 0 0  !important;
        border: 0 !important;
      }
    }
    button {
      border: 0;
      background: var(--bg-surface-color);
      &:hover {
        background-color: var(--bg-surface-color) !important;
      }
      &:focus {
        outline: 0 none !important;
        outline-offset: 0 !important;
        box-shadow: 0 0  !important;
        border: 0 !important;
      }
      span {
        color: var(--primary-color-text);
      }
    }
  }
}

.line-chart-datepicker-position {
  right: 73px;
  left: unset !important;
}

.menu-bar-width {
  display: flex;
  width: 100%;

  div {
    width: 100%;

    .p-element {
      ul {
        flex-wrap: unset;
      }
    }
  }
}

.inputswitch-padding-override {
  div {
    div {
      padding: .5rem 2rem;
    }
  }
}

.display-none {
  display: none !important;
}

.atom-firmware-upload {
  .p-progressbar {
    display: none !important;
  }

  .p-fileupload-files {
    display: none !important;
  }
}

.progress-buttonbar-flex-centered {
  .p-fileupload-buttonbar {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

.firmware-checkbox {
  height: 42px;
  width: 32px;
}

.upload-file-active {
  .p-fileupload {
    .p-fileupload-buttonbar {
      display: none !important;
    }

    .p-fileupload-content {
      border-radius: 4px;
    }
  }
}

.pilot-throbber-size-md {
  width: 16px !important;
  height: 16px !important;
}

.table-throbber-size-sm {
  width: 12px !important;
  height: 12px !important;
}

.table-progress-size {
  height: 12px !important;
  width: 100% !important;
}

.table-progress-style {
  height: 12px !important;
  width: 100% !important;
  background: var(--chart-border-color) !important;
}

.pilot-progress-size {
  height: 12px !important;
  width: 140px !important;
}

.progress-style {
  background: var(--chart-border-color) !important;
}

.p-toggle-button-inactive-hover {

  &not {

  }
}
