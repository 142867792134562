
////////////////////////////////////////////////////////////
//       ______ __     ______ _  __  ____   ____  _  __   //
//      / ____// /    / ____/| |/ / / __ ) / __ \| |/ /   //
//     / /_   / /    / __/   |   / / __  |/ / / /|   /    //
//    / __/  / /___ / /___  /   | / /_/ // /_/ //   |     //
//   /_/    /_____//_____/ /_/|_|/_____/ \____//_/|_|     //
////////////////////////////////////////////////////////////

////////////////////////////
// ╔═╗╔═╗╔═╗
// ║ ╦╠═╣╠═╝
// ╚═╝╩ ╩╩

.gap-29px {
  gap: 29px;
}

.gap-10px {
  gap: 10px;
}

////////////////////////////////////////////////////////////
//       _____  ____   ___    ______ ____ _   __ ______   //
//     / ___/ / __ \ /   |  / ____//  _// | / // ____/    //
//     \__ \ / /_/ // /| | / /     / / /  |/ // / __      //
//    ___/ // ____// ___ |/ /___ _/ / / /|  // /_/ /      //
//   /____//_/    /_/  |_|\____//___//_/ |_/ \____/       //
////////////////////////////////////////////////////////////

////////////////////////////
// ╔═╗╔═╗╔╦╗╔╦╗╦╔╗╔╔═╗
// ╠═╝╠═╣ ║║ ║║║║║║║ ╦
// ╩  ╩ ╩═╩╝═╩╝╩╝╚╝╚═╝

.mb-29px {
  margin-bottom: 29px;
}

////////////////////////////
// ╔═╗╔═╗╔╦╗╔╦╗╦╔╗╔╔═╗
// ╠═╝╠═╣ ║║ ║║║║║║║ ╦
// ╩  ╩ ╩═╩╝═╩╝╩╝╚╝╚═╝

.pr-40px {
  padding-right: 40px;
}

.pl-40px {
  padding-left: 40px;
}

////////////////////////////////////////////////////////////
//          _____  ____ _____    ____ _   __ ______       //
//         / ___/ /  _//__  /   /  _// | / // ____/       //
//         \__ \  / /    / /    / / /  |/ // / __         //
//        ___/ /_/ /    / /__ _/ / / /|  // /_/ /         //
//       /____//___/   /____//___//_/ |_/ \____/          //
////////////////////////////////////////////////////////////

.set-w-8rem {
  width: 8rem !important;
  min-width: 8rem !important;
  max-width: 8rem !important;
}

.set-w-10rem {
  width: 10rem !important;
  min-width: 10rem !important;
  max-width: 10rem !important;
}

.set-w-14rem {
  width: 14rem !important;
  min-width: 14rem !important;
  max-width: 14rem !important;
}

///// max-w

.max-w-184px {
  max-width: 184px;
}

.max-w-8rem {
  max-width: 8rem;
}

////////////////////////////
// ╔╦╗╦╔╗╔   ╦ ╦
// ║║║║║║║───║║║
// ╩ ╩╩╝╚╝   ╚╩╝

.min-w-10rem {
  min-width: 10rem;
}

.min-w-15rem {
  min-width: 15rem;
}

.min-w-400px {
  min-width: 400px;
}

.min-w-184px {
  min-width: 184px;
}

.min-w-8rem {
  min-width: 8rem;
}
