///////////////////////////////////////////////
//                 SVG DATA                  //
///////////////////////////////////////////////

.atom-power-logo {
  mask: url(../../../assets/svg/atom-power.svg) no-repeat center / contain;
  background-color: var(--nav-logo);
  width: 176px;
  height: 26px;
}

.magnifying-glass-24px-icon {
  mask: url(../../../assets/svg/magnifying-glass-24px.svg) no-repeat center / contain;
  background-color: var(--nav-item-inverted);
  width: 24px;
  height: 24px;
}

.download-24px-icon {
  mask: url(../../../assets/svg/download-24px.svg) no-repeat center / contain;
  background-color: var(--nav-item-inverted);
  width: 24px;
  height: 24px;
}

.building-icon {
  mask: url(../../../assets/svg/building-24px.svg) no-repeat center / contain;
  background-color: var(--nav-item-inverted);
  width: 24px;
  height: 24px;
}

.location-pin-icon {
  mask: url(../../../assets/svg/location-pin-24px.svg) no-repeat center / contain;
  background-color: var(--nav-item-inverted);
  width: 24px;
  height: 24px;
}

.icon-purple-bg-color {
  background-color: #490089 !important;
}

.checkbox-icon {
  mask: url(../../../assets/svg/checkbox-icon.svg) no-repeat center / contain;
  background-color: var(--nav-item-inverted);
  width: 24px;
  height: 24px;
}

.pencil-icon {
  mask: url(../../../assets/svg/pencil-icon.svg) no-repeat center / contain;
  background-color: var(--nav-collapsed-badge);
  width: 21px;
  height: 21px;
}

////////////////////////////////////////////////////////////////////////////////
//                              Material Colors                               //
////////////////////////////////////////////////////////////////////////////////

.color-mat-purple {
  color: var(--material-fill-purple);
}

.color-mat-green {
  color: var(--material-fill-green);
}

.color-mat-teal {
  color: var(--material-fill-teal);
}

.color-mat-cyan {
  color: var(--material-fill-cyan);
}

.background-mat-purple {
  background-color: var(--material-accent-purple);
}

.background-mat-green {
  background-color: var(--material-accent-green);
}

.background-mat-teal {
  background-color: var(--material-accent-teal);
}

.background-mat-cyan {
  background-color: var(--material-accent-cyan);
}

////////////////////////////////////////////////////////////////////////////////
//                                WIDGET STATE                                //
////////////////////////////////////////////////////////////////////////////////

.good-icon {
  mask: url(../../../assets/svg/widgetStates/good-icon.svg) no-repeat center / contain;
  background-color: #067647;
  width: 24px;
  height: 24px;
}

.good-background-color {
 background-color: var(--good-icon-color)
}

.good-color {
  color: var(--good-icon-color);
}

.good-circle-icon {
  mask: url(../../../assets/svg/widgetStates/good-circle.svg) no-repeat center / contain;
  background-color: #067647;
  width: 64px;
  height: 64px;
}

.info-icon {
  mask: url(../../../assets/svg/widgetStates/info-icon.svg) no-repeat center / contain;
  background-color: #490089;
  width: 24px;
  height: 24px;
}

.info-circle-icon {
  mask: url(../../../assets/svg/widgetStates/info-circle.svg) no-repeat center / contain;
  background-color: #DBC2F1;
  width: 64px;
  height: 64px;
}

.warning-icon {
  mask: url(../../../assets/svg/widgetStates/warning-icon.svg) no-repeat center / contain;
  background-color: #B54708;
  width: 24px;
  height: 24px;
 }

.warning-background-color {
  background-color: var(--analytics-error-state);
}

.warning-color {
  color: var(--analytics-error-state);
}

.warning-circle-icon {
  mask: url(../../../assets/svg/widgetStates/warning-circle.svg) no-repeat center / contain;
  background-color: #FFFAEB;
  width: 64px;
  height: 64px;
}

.error-icon {
  mask: url(../../../assets/svg/widgetStates/error-icon.svg) no-repeat center / contain;
  background-color: #B42318;
  width: 24px;
  height: 24px;
}

.error-circle-icon {
  mask: url(../../../assets/svg/widgetStates/error-circle.svg) no-repeat center / contain;
  background-color: #FEE4E2;
  width: 64px;
  height: 64px;
}

////////////////////////////////////////////////////////////////////////////////
//                               TABLES                                       //
////////////////////////////////////////////////////////////////////////////////

.table-cell-error-icon {
  mask: url(../../../assets/svg/tables/table-cell-error.svg) no-repeat center / contain;
  background-color: #F04438;
  width: 19px;
  height: 16px;
}

.table-email-icon {
  mask: url(../../../assets/svg/tables/table-email.svg) no-repeat center / contain;
  background-color: var(--nav-collapsed-badge);
  width: 24px;
  height: 24px;
}

.table-lock-icon {
  mask: url(../../../assets/svg/tables/table-lock.svg) no-repeat center / contain;
  background-color: var(--nav-item-inverted);
  width: 25px;
  height: 24px;
}

////////////////////////////////////////////////////////////////////////////////
//                              LEFT NAV                                      //
////////////////////////////////////////////////////////////////////////////////

.ln-dashboard-icon {
  mask: url(../../../assets/svg/navigation/dashboard.svg) no-repeat center / contain;
  width: 24px;
  height: 24px;
}

.ln-analytics-icon {
  mask: url(../../../assets/svg/navigation/analytics.svg) no-repeat center / contain;
  width: 24px;
  height: 24px;
}

.ln-sites-icon {
  mask: url(../../../assets/svg/location-pin-24px.svg) no-repeat center / contain;
  width: 24px;
  height: 24px;
}

.ln-life-saver-icon {
  mask: url(../../../assets/svg/navigation/life-saver.svg) no-repeat center / contain;
  width: 24px;
  height: 24px;
}

.atom-purple {
  fill: var(--nav-collapsed-badge) !important;
}

.ln-svg {
  background-color: var(--nav-item-inverted);
}

.ln-svg-selected {
  background-color: var(--nav-item);
}

.error-color-red {
  color: var(--alert-messaging-error-text-color, #F04438) !important;
}

.atom-color-purple {
  color: var(--nav-collapsed-badge) !important;
}
