////////////////////////////////////////////////////
//                 Table styling                  //
////////////////////////////////////////////////////
 
 
// Table selected row styling
//--------------------------------------------------
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background:  var(--site-feedback-hover-color);
  color: var(--nav-item);
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: var(--nav-collapsed-badge);
  color: var(--nav-item);
}
//--------------------------------------------------

.table-container {
  width: 100%
}

.header-default-cursor {
  cursor: default !important;
}

.col-border {
  border-right: 1px solid var(--table-border-right) !important;
  max-width: 177px !important;
}

.right-header-container {
  display: flex;
  padding: var(--tabs-tabset-min-width, 0rem);
  justify-content: flex-end;
  align-items: center;
  gap: var(--spacing-2-x, 0.5rem);
  border-radius: var(--tabs-tabset-min-width, 0rem);
}

.table-result-count {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  color: var(--primary-color-text) !important;
}

.bottom-border-cell {
  border-bottom: 1px solid var(--table-border-right, #D0D5DD) !important;
}

.no-left-border-override {
  border-left: 0 !important;
  border-radius: 0 !important;
}

.col-name-size {
  max-width: 177px !important;
  width: 177px !important;
  min-width: 177px !important;
}

.max-col-name-span-size {
  max-width: 145px !important;
}

.col-unfrozen-cell {
  min-width: 200px !important;
}

.count-text-container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.625rem;
}

.optional-column-padding {
  div {
    .p-datatable-header {
      padding: 0 0 .5rem !important;
    }
  }
}

.row-table-flex {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.min-w-120px {
  min-width: 120px;
}

.min-w-136px {
  min-width: 136px;
}

.row-cell-flex {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.col-header-container {
  padding: 0.5rem 1rem;
  gap: 8px;
  justify-content: center;
  align-self: stretch;
}

.col-checkbox-size {
  width: 54px !important;
  min-width: 54px !important;
  max-width: 54px !important;
}

.col-standard-size {
  width: 11.1875rem;
  min-width: 11.1875rem;
  border-bottom: 1px solid var(--borders-dividers-table-divider, #475467);
}

.col-standard-size-xl {
  width: 13rem;
  min-width: 13rem;
  border-bottom: 1px solid var(--borders-dividers-table-divider, #475467);
}

.col-border-cell {
  border-right: 1px solid var(--table-border-right) !important;
  max-width: 177px !important;
}

.cell-clickable-link {
  color: var(--nav-collapsed-badge) !important;
  text-wrap: nowrap;
  text-decoration: none;
}

.cell-clickable-link:hover {
  text-decoration: underline;
}

.base-color {
  fill: var(--nav-item-inverted) !important;
  stroke: var(--nav-item-inverted) !important;
}

.inactive {
  fill: var(--table-inactive) !important;
  stroke: var(--table-inactive) !important;
}

.flex-table-sort-icon-margin {
  div {
    div {
      table {
        thead {
          tr {
            th {
              p-sorticon {
                span {
                  margin-left: 0 !important;
                  display: flex;
                }
              }
            }
          }
        }
      }
    }
  }
}

.tabview-sizing {
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
  @media only screen and (min-width: 992px) {
    max-width: 40rem;
  }
}

.tabview-with-button-sizing {
  @media only screen and (max-width: 901px) {
    width: 100%;
  }
  @media only screen and (min-width: 902px) {
    max-width: 29rem;
  }
}

.custom-header {
  height: 54px;
  padding: 16px 32px;
}

.edit-tab {
  display: flex;

  .p-tabview.p-component {
    display: flex;
    width: 100%;
    flex-grow: 1;

    .p-tabview-nav-container {
      display: flex;
      width: 100%;

      .p-tabview-nav-content {
        display: flex;
        width: 100%;

        .p-tabview-nav {
          flex-grow: 1;

          .p-tabview-ink-bar{
            display: none !important;
          }

          li {
            display: flex;
            flex-grow: 1;
            width: 100%;

            .p-tabview-nav-link {
              width: 100%;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

////////////////////////////////////////////////////
//                 Table styling                  //
////////////////////////////////////////////////////

.right-header-table-container {
  display: flex;
  padding: var(--tabs-tabset-min-width, 0rem);
  justify-content: flex-end;
  align-items: center;
  gap: var(--spacing-2-x, 0.5rem);
  border-radius: var(--tabs-tabset-min-width, 0rem);
}

.right-header-search-bar {
  width: 17.25rem;
  padding-right: 5px;
}

.search-bar-input-width {
  width: 276px
}

.mag-glass-expanded {
  left: 206px;
  top: 6px;
  flex-shrink: 0;
}

.actions-container {
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.right-header-button-svg {
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
}

.actions-svg-container {
  display: flex;
  width: 32px;
  height: 32px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.day-size {
  max-width: 230px;
  min-width: 230px;
  width: 230px
}

.delete-button {

}

.delete-button:hover {
  border-radius: 4px;
  background: var(--table-inactive);
}

.list-box-table {
  width: 100%;
}

.list-box-width-300px {
  min-width: 300px;
  width: 300px;
  max-width: 300px;
}

.listbox-table-height-471px{
  max-height: 471px;
  min-height: 471px;
}

.listbox-table-height-420px {
  max-height: 420px;
  min-height: 420px;
}

.enabled-container {
  display: flex;
  width: 100%;
  padding: var(--Spacing-1X, 4px) var(--Spacing-2X, 8px);

  justify-content: center;
  align-items: center;
}

.enabled-label {
  display: flex;
  padding: 4px 8px;
  height: 1.5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
}

.label-on {
  border: 1px solid var(--chart-green);
  color: var(--chart-green);
}

.label-off {
  border: 1px solid var(--chart-chip-blue-gray);
  color: var(--chart-chip-blue-gray);
}

.label-available {
  border: 1px solid var(--chart-teal);
  color: var(--chart-teal);
}

.label-unavailable {
  border: 1px solid var(--chart-chip-blue-gray);
  color: var(--chart-chip-blue-gray);
}

.label-charging{
  border: 1px solid var(--chart-purple);
  color: var(--chart-purple);
}

.label-faulted {
  border: 1px solid var(--chart-red);
  color: var(--chart-red);
}

.label-idle {
  border: 1px solid var(--chart-yellow);
  color: var(--chart-yellow);
}

.custom-search {
  width: 48px;
  height: 48px;
}

.custom-search:hover {
  border-radius: 4px;
  background: var(--nav-bg-hover);
}

.search-container {
  width: 48px;
  height: 48px;
}

.search-box-full {
  width: 276px;
  height: 42px;
}

.search-box-full-icon {
  margin-top: -0.8rem !important;
  top: 22px !important;
  left: 240px;
}
